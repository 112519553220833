<template>
  <ContentWrapper :links="topLinks">
    <Form inline>
      <FormItem>
        <ButtonGroup>
          <Button
            type="primary"
            @click="
              dialogDisplay = true
              dialogTitle = '新建管理员'
            "
            >新建</Button
          >
        </ButtonGroup>
      </FormItem>
      <FormItem>
        <Input v-model="queryForm.name" type="text" placeholder="搜索名称 / 邮箱 / 电话" />
      </FormItem>
      <FormItem>
        <FormItem>
          <Select v-model="queryForm.status" placeholder="状态">
            <Option :value="-1">不限</Option>
            <Option :value="1">正常</Option>
            <Option :value="0">禁用</Option>
          </Select>
        </FormItem>
      </FormItem>
      <FormItem>
        <Select v-model="queryForm.roleAlias" placeholder="用户角色">
          <Option value="">不限</Option>
          <Option v-for="roleItem in allRoles" :value="roleItem.roleAlias" :key="roleItem.name">
            {{ roleItem.title }}
          </Option>
        </Select>
      </FormItem>
      <FormItem>
        <DatePicker
          v-model="createdAtRange"
          type="daterange"
          placement="bottom-end"
          placeholder="创建日期范围"
          style="width: 200px"
        ></DatePicker>
      </FormItem>
    </Form>
    <Table stripe :columns="columns" :data="adminList" :loading="isLoading"></Table>
    <Pager :total="adminListCount" :current.sync="page" />
    <Modal v-model="dialogDisplay" :title="dialogTitle" @on-ok="onFormSubmit">
      <AdminEditor ref="adminEditor" :withPassword="dialogCreateAdminMode" />
    </Modal>
    <Modal v-model="dialogPasswordDisplay" title="修改密码" @on-ok="onPasswordSubmit">
      <PasswordEditor ref="passwordEditor" />
    </Modal>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import AdminEditor from "@/components/admin/AdminEditor.vue"
import PasswordEditor from "@/components/admin/PasswordEditor.vue"
import Pager from "@/components/Pager.vue"
import { queryAdmin, deleteAdmin, createAdmin, updateAdmin, updateAdminPassword, queryAllRoles } from "@/api"
import { toHumanDate } from "@/util"

export default {
  name: "AdminQueryPage",
  components: { ContentWrapper, Pager, AdminEditor, PasswordEditor },
  data() {
    return {
      allRoles: [],
      topLinks: [{ title: "管理员管理", link: this.$route.path }],
      createdAtRange: ["", ""],
      queryForm: { name: "", status: -1, roleAlias: "" },
      page: 1,
      isLoading: false,
      adminList: [],
      adminListCount: 0,
      dialogDisplay: false,
      dialogCreateAdminMode: true,
      dialogTitle: "",
      dialogPasswordDisplay: false,
      columns: [
        { title: "标识", key: "alias", width: 100, maxWidth: 100 },
        { title: "名称", key: "name", width: 80, maxWidth: 140 },
        { title: "电话", key: "telephone", width: 120, maxWidth: 150 },
        { title: "邮箱", key: "email", width: 180, maxWidth: 200 },
        { title: "备注", key: "comment" },
        {
          title: "角色",
          width: 110,
          maxWidth: 110,
          render: (h, params) => h("Tag", params.row.role.title),
        },
        {
          title: "状态",
          maxWidth: 60,
          render: (h, params) => {
            return h("div", params.row.status ? "开启" : "禁用")
          },
        },
        {
          title: "创建时间",
          maxWidth: 110,
          render: (h, params) => h("div", toHumanDate(params.row.createdAt)),
        },
        {
          title: "更新时间",
          maxWidth: 110,
          render: (h, params) => h("div", toHumanDate(params.row.updatedAt)),
        },
        {
          title: "操作",
          width: 200,
          maxWidth: 200,
          render: (h, params) => {
            return h("ButtonGroup", [
              h(
                "Button",
                {
                  props: { type: "success" },
                  on: {
                    click: this.edit.bind(this, params.row),
                  },
                },
                "编辑"
              ),
              h(
                "Button",
                {
                  props: {},
                  on: {
                    click: this.editPassword.bind(this, params.row),
                  },
                },
                "修改密码"
              ),
              h(
                "Button",
                {
                  props: { type: "warning" },
                  on: {
                    click: () => {
                      this.$Modal.confirm({
                        content: "确认删除吗?",
                        onOk: () => {
                          this.delete(params.row.alias)
                        },
                      })
                    },
                  },
                },
                "删除"
              ),
            ])
          },
        },
      ],
    }
  },
  computed: {},
  watch: {
    async page() {
      await this.reload()
    },
    queryForm: {
      deep: true,
      async handler() {
        await this.reload()
      },
    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      try {
        this.allRoles = await queryAllRoles()
        this.isLoading = true
        const status = this.queryForm.status != -1 ? this.queryForm.status : null
        let startTime = null,
          endTime = null
        if (this.createdAtRange && this.createdAtRange.length == 2 && this.createdAtRange[0] instanceof Date) {
          startTime = this.createdAtRange[0].getTime()
          endTime = this.createdAtRange[1].getTime()
        }
        const { items, count } = await queryAdmin(
          this.page,
          this.queryForm.name,
          this.queryForm.roleAlias,
          startTime,
          endTime,
          status
        )
        this.adminList = items
        this.adminListCount = count
        this.isLoading = false
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    edit(row) {
      const formData = Object.assign({}, row)
      formData.roleAlias = row.role.roleAlias
      this.$refs.adminEditor.formData = formData
      this.dialogCreateAdminMode = false
      this.dialogDisplay = true
      this.dialogTitle = "编辑管理员"
    },
    editPassword(row) {
      this.$refs.passwordEditor.formData = Object.assign(
        {},
        {
          alias: row.alias,
          telephone: row.telephone,
          email: row.email,
          originalPassword: "",
          repeatPassword: "",
          name: row.name,
        }
      )
      this.dialogPasswordDisplay = true
    },
    async delete(alias) {
      try {
        await deleteAdmin(alias)
        await this.reload()
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    async onFormSubmit() {
      try {
        if (this.dialogCreateAdminMode) {
          await createAdmin(this.$refs.adminEditor.formData)
          this.$Message.success("新建成功")
        } else {
          await updateAdmin(this.$refs.adminEditor.formData)
          this.$Message.success("更新成功")
        }
        this.dialogDisplay = false
      } catch (e) {
        this.$Message.error(e.message)
        this.dialogDisplay = true
      } finally {
        await this.reload()
      }
    },
    async onPasswordSubmit() {
      try {
        await updateAdminPassword(this.$refs.passwordEditor.formData)
        this.dialogPasswordDisplay = false
        this.$Message.success("修改成功，该用户须重新登录")
      } catch (e) {
        this.$Message.error(e.message)
        this.dialogPasswordDisplay = true
      }
    },
  },
}
</script>
