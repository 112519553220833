<template>
  <Form :labelWidth="100" :data="formData">
    <FormItem label="显示名称">
      <Input v-model="formData.name" placeholder="显示名称" />
    </FormItem>
    <FormItem label="手机">
      <Input v-model="formData.telephone" placeholder="手机号，此为登录标识" />
    </FormItem>
    <FormItem label="电子邮箱">
      <Input v-model="formData.email" placeholder="电子邮箱" />
    </FormItem>
    <template v-if="withPassword">
      <FormItem label="密码">
        <Input v-model="formData.originalPassword" type="password" placeholder="密码" />
      </FormItem>
      <FormItem label="密码确认">
        <Input v-model="formData.repeatPassword" type="password" placeholder="密码确认" />
      </FormItem>
    </template>
    <FormItem label="备注">
      <Input v-model="formData.comment" placeholder="备注" />
    </FormItem>
    <FormItem label="状态">
      <i-switch v-model="formData.status" placeholder="图标" :true-value="1" :false-value="0" />
    </FormItem>
    <FormItem label="角色">
      <Select v-model="formData.roleAlias">
        <Option v-for="roleItem in allRoles" :value="roleItem.roleAlias" :key="roleItem.name">
          {{ roleItem.title }}
        </Option>
      </Select>
    </FormItem>
  </Form>
</template>

<script>
import { queryAllRoles } from "@/api"
import { apiUtil } from "@/util"

const initData = () => {
  return {
    name: "",
    telephone: "",
    email: "",
    status: 1,
    comment: "",
    roleAlias: "",
    originalPassword: "",
    repeatPassword: "",
  }
}

export default {
  name: "AdminEditor",
  components: {},
  props: {
    admin: {
      type: Object,
      default: () => initData(),
    },
    withPassword: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      formData: Object.assign({}, this.admin),
      allRoles: [],
    }
  },
  computed: {},
  watch: {
    admin(newC) {
      this.formData = Object.assign({}, newC)
    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      apiUtil.call(this, async () => {
        this.allRoles = await queryAllRoles()
      })
    },
    doReset() {},
  },
}
</script>

<style></style>
