<template>
  <Form :labelWidth="100" :data="formData">
    <FormItem label="名称">
      <Input v-model="formData.name" disabled />
    </FormItem>
    <FormItem label="电话">
      <Input v-model="formData.telephone" disabled />
    </FormItem>
    <FormItem label="邮箱">
      <Input v-model="formData.email" disabled />
    </FormItem>
    <FormItem label="密码">
      <Input v-model="formData.originalPassword" type="password" placeholder="密码" />
    </FormItem>
    <FormItem label="密码确认">
      <Input v-model="formData.repeatPassword" type="password" placeholder="密码确认" />
    </FormItem>
  </Form>
</template>

<script>
const initData = {
  alias: "",
  name: "",
  telephone: "",
  email: "",
  originalPassword: "",
  repeatPassword: "",
}
export default {
  name: "PasswordEditor",
  components: {},
  props: {},
  data() {
    return {
      formData: initData,
    }
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    doReset() {
      this.formData = Object.assign({}, initData)
    },
  },
}
</script>

<style></style>
